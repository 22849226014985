<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <message-links-comp></message-links-comp>
                <div class="board_list">
                    <div class="logo_panel">
                        <h4 class="pay_title">보낸쪽지함</h4>
                        <div class="board_list" v-for="(item, index) in messageList" :key="index" style="border-bottom: 1px solid #000000">
                            <div class="my_question">
                              <span class="time">
                                  <span><i class="fa fa-user"></i> {{item.user.nickname}}</span>
                                <span v-if="item.checked ===  sportsConst.UNCHECKED"> [미확인] </span>
                                <i class="fa fa-question-circle-o" aria-hidden="true"></i> {{item.createTime|datef('MM/DD HH:mm')}}
                              </span>
                                <div class="qs" :class="{'unchecked':item.checked===sportsConst.UNCHECKED}"
                                     v-if="item.type === sportsConst.MESSAGE_TYPE_PRIVATE" v-html="item.content"></div>
                                <div class="qs" :class="{'unchecked':item.checked===sportsConst.UNCHECKED}"
                                     v-if="item.type === sportsConst.MESSAGE_TYPE_PUBLIC"
                                     v-html="item.publicMessage.content"></div>
                            </div>
                        </div>
                        <!--페이지-->
                        <pagination :page-index="pageNum"
                                    :total="total"
                                    :page-size="pageSize"
                                    @change="pageChange"
                                    v-if="messageList.length > 0"></pagination>

                    </div>
                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import SubTitle from "../../components/SubTitle";
    import Pagination from "../../components/pagenation/Pagination";
    import {
        checkedMessageAll,
        checkedMessageById,
        deleteMessageAll,
        deleteMessageById,
        getMessageList, getSendMessage, move2box
    } from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import MessageLinksComp from "../../components/links/MessageLinksComp";

    export default {
        name: "MessageSendHistory",
        components: {
            MessageLinksComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            TopbarComp, FootComp, LeftBarComp, Pagination, SubTitle, RightBarComp
        },
        data() {
            return {
                messageList: [],
                pageNum: 1,
                pageSize: 10,
                total: 1,
                sportsConst,
            }
        },
        methods: {

            initMessageList() {
                getSendMessage(this.pageNum, this.pageSize).then(res => {
                    if (res.data.success) {
                        this.total = res.data.total
                        this.messageList = res.data.data
                    }
                })
            },
            pageChange(page) {
                this.pageNum = page
                this.initMessageList()
            },
        },
        created() {
            this.initMessageList()
        }
    }
</script>

<style scoped>
    .board_list .logo_panel{
        padding-top: 0px;
    }
    .board_list {
        text-align: left;
        box-sizing: border-box;
        padding-left: 10px;
    }

    .board_list .my_question {
        font-size: 14px;
        box-sizing: border-box;
        padding: 10px 10px;
    }

    .my_question div {
        font-size: 14px;
    }

    .my_question .time {
        display: block;
        line-height: 30px;
        width: 100%;
        color: #f19f09;
    }

    .my_question .time button {
        width: 50px;
        height: 20px;
        font-size: 12px;
        padding: 0 !important;
        margin-left: 6px;
    }

    .my_question .unchecked {
        background-color: #34343c;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px;
    }

    .message_btns {
        text-align: left;
    }

    .message_btns button {
        width: 120px;
        height: 30px;
        margin: 10px 0 10px 0;

    }

    .message_btns button:last-child {
        margin-left: 10px;
    }

</style>